<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col md="8" sm="6">
        <div>
          <h1>ADJUST EVALUATION OPPORTUNITIES</h1>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationList',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <datatable
      :headers="headers"
      :staticData="evaluations"
      :componentURL="componentURL"
      :search="false"
      @row-clicked="customerDetail"
    ></datatable>

    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Legal first name*"
                  v-model="instance.customer"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="City"
                  v-model="instance.city"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Date*"
                  v-model="instance.date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Date*"
                  v-model="instance.date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Time"
                  v-model="instance.time"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="DTmin"
                  v-model="instance.dtmin"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <a :href="'tel:' + instance.telephone">{{
                  instance.telephone
                }}</a>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancelAdjustment">
            Disagree
          </v-btn>
          <v-btn color="green darken-1" text @click="doAdjustment">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";

export default {
  components: { Datatable },

  data() {
    return {
      componentURL: "",
      dialog: false,
      instance: {
        id: null,
        customer: null,
        city: null,
        date: null,
        time: null,
        dtmin: null,
        telephone: null,
      },
      headers: [
        { text: "Customer", value: "customer" },
        { text: "City", value: "city" },
        { text: "Date", value: "date" },
        { text: "Time", value: "time" },
        { text: "DTmin", value: "dtmin" },
        { text: "Telephone", value: "telephone" },
      ],
      evaluations: [
        {
          id: 1,
          customer: "John Customer",
          city: "Overland Park",
          date: "9/14/2020",
          time: "9:00",
          dtmin: "6",
          telephone: "+911-936-3639",
        },
        {
          id: 2,
          customer: "John Customer",
          city: "Overland Park",
          date: "9/14/2020",
          time: "9:00",
          dtmin: "6",
          telephone: "+911-936-3639",
        },
        {
          id: 3,
          customer: "John Customer",
          city: "Overland Park",
          date: "9/14/2020",
          time: "9:00",
          dtmin: "6",
          telephone: "+911-936-3639",
        },
        {
          id: 4,
          customer: "John Customer",
          city: "Overland Park",
          date: "9/14/2020",
          time: "9:00",
          dtmin: "6",
          telephone: "+911-936-3639",
        },
        {
          id: 5,
          customer: "John Customer",
          city: "Overland Park",
          date: "9/14/2020",
          time: "9:00",
          dtmin: "6",
          telephone: "+911-936-3639",
        },
        {
          id: 6,
          customer: "John Customer",
          city: "Overland Park",
          date: "9/14/2020",
          time: "9:00",
          dtmin: "6",
          telephone: "+911-936-3639",
        },
        {
          id: 7,
          customer: "John Customer",
          city: "Overland Park",
          date: "9/14/2020",
          time: "9:00",
          dtmin: "6",
          telephone: "+911-936-3639",
        },
      ],
    };
  },
  methods: {
    customerDetail(value) {
      this.instance = value;
      this.dialog = true;
    },
    cancelAdjustment() {
      this.dialog = false;
      this.instance = {
        id: null,
        customer: null,
        city: null,
        date: null,
        time: null,
        dtmin: null,
        telephone: null,
      };
    },

    doAdjustment() {
      this.dialog = false;
      const index = this.evaluations.indexOf(
        (item) => item.id === this.instance.id
      );
      //Update single instance of evaluation by API

      if (index > -1) {
        this.evaluations[index] = this.instance;
      }
      // Refetch Table Data
    },
  },
};
</script>

<style type="text/css">
.v-application .primary--text {
  color: #7e8594 !important;
  caret-color: #7e8594 !important;
}

.v-input
  theme--light
  .v-input--selection-controls
  .v-input--radio-group
  .v-input--radio-group--row {
  margin-left: 5% !important;
}
</style>
